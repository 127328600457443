// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
//import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyDjb4WmUqpAMbmQgXTVwqUsjR7i0zpQS38",
  authDomain: "sandali-singh.firebaseapp.com",
  projectId: "sandali-singh",
  storageBucket: "sandali-singh.appspot.com",
  messagingSenderId: "116790636632",
  appId: "1:116790636632:web:1adb7e6e99001db2705a2d",
  measurementId: "G-7KJEQ28TQ8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
//const analytics = getAnalytics(app);
